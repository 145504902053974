import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Button,
    Heading,
    Text
} from '@chakra-ui/react';
import {Link} from "react-router-dom";

function AccountDetails({userId}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [token, setToken] = useState('');

    const [saved, setSaved] = useState(null);

    // Fetch user details
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(`/userdetails`);
                console.log(response.data)
                const {email, name, availableTokens} = response.data;
                setEmail(email);
                setName(name);
                setToken(availableTokens);
            } catch (error) {
                console.error('Failed to fetch user details:', error);
            }
        };

        fetchUserDetails();
    }, [userId]);

    const handleSave = async () => {
        setSaved(false);
        console.log('Saved:', {email, password, name});

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await axios.post("/updateUserDetails", {
                name,
                email,
                password
            }, config);

            setSaved(true)
        } catch (error) {
            console.error('Failed to fetch user details:', error);
            setSaved(false)
        }
    };

    return (
        <Box p={5} maxW="500px" borderWidth="1px" borderRadius="lg" overflow="hidden">
            <Heading mb={6}>Account Details</Heading>
            <FormControl id="email" mb={4}>
                <FormLabel>Email (Cannot be changed)</FormLabel>
                <Text fontSize="md" p={2} bgColor="gray.100" borderRadius="md">{email}</Text>
            </FormControl>
            <FormControl id="password" isRequired mb={4}>
                <FormLabel>Password</FormLabel>
                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
            </FormControl>
            <FormControl id="name" isRequired mb={4}>
                <FormLabel>Name</FormLabel>
                <Input value={name} onChange={(e) => setName(e.target.value)}/>
            </FormControl>
            <FormControl id="token" mb={4}>
                <FormLabel>Available Tokens</FormLabel>
                <Text fontSize="md" p={2} bgColor="gray.100" borderRadius="md">{token}</Text>
            </FormControl>
            <Button colorScheme="blue" onClick={handleSave} p={5}>Save Changes</Button>
            { saved && <Text>{saved ? "Saved" : "Save Failed" }</Text> }
        </Box>
    );
}

export default AccountDetails;
