import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import React from "react";
import {oneLight} from 'react-syntax-highlighter/dist/esm/styles/prism';

export function CodeBlock({codeString, language = "swift", ...props}) {
    return (
            <SyntaxHighlighter
                {...props}
                children={String(codeString).replace(/\n$/, '')}
                language={language}
                PreTag="div"
                resize="vertical"
                overflow="auto"
                style={oneLight}
            >
                {codeString}
            </SyntaxHighlighter>
    );
}