import React, {useEffect, useState} from "react";
import {
    Button,
    Collapse,
    GridItem,
    Heading,
    HStack,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Spacer,
    Text,
    Textarea,
    VStack
} from "@chakra-ui/react";
import {ChevronDownIcon, ChevronUpIcon, InfoOutlineIcon} from "@chakra-ui/icons";

export function CollapsibleTextareaWithInfo({
                                                heading,
                                                contentTitle,
                                                popoverBody,
                                                placeholderText,
                                                enteredValue,
                                                setEnteredValue,
                                                disabled,
                                                initiallyCollapsed
                                            }) {
    const handleTextChange = (event) => {
        setEnteredValue(event.target.value);
    };

    const handleSummaryClick = () => {
        setIsTextAreaOpen(!isTextAreaOpen);
    };

    const [isTextAreaOpen, setIsTextAreaOpen] = useState(true);
    const toggleTextArea = () => {
        setIsTextAreaOpen(!isTextAreaOpen);
    };

    useEffect(() => {
        console.log(enteredValue)
    }, [enteredValue]);

    useEffect(() => {
        setIsTextAreaOpen(!initiallyCollapsed)
    }, [initiallyCollapsed]);

    return (
        <GridItem colSpan={2} w="100%">
            <HStack width="100%">
                <Heading as='h4' size='sm'>{heading}</Heading>
                <Popover>
                    <PopoverTrigger>
                        <IconButton aria-label='Info' variant="ghost" icon={<InfoOutlineIcon/>}/>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow/>
                        <PopoverCloseButton/>
                        <PopoverHeader>Information</PopoverHeader>
                        <PopoverBody>{popoverBody}</PopoverBody>
                    </PopoverContent>
                </Popover>
                <Spacer/>
                <Button ml={3} onClick={toggleTextArea}>
                    {isTextAreaOpen ? <ChevronUpIcon/> : <ChevronDownIcon/>}
                </Button>
            </HStack>
            <VStack width="100%" alignItems="flex-start">
                <Text w="100%" onClick={handleSummaryClick} visibility={isTextAreaOpen ? 'hidden' : 'visible'}>
                    {contentTitle ? contentTitle : (enteredValue ? `${enteredValue.split('\n')[0].substr(0, 20)}...` : placeholderText)}
                </Text>
                <Collapse in={isTextAreaOpen} style={{width: "100%"}}>
                    <Textarea
                        placeholder={placeholderText}
                        value={enteredValue}
                        onChange={handleTextChange}
                        borderColor="gray.500"
                        borderWidth="2px"
                        minH="50vh"
                        w="100%"
                        disabled={disabled}
                    />
                </Collapse>
            </VStack>
        </GridItem>
    );
}