import React, {useEffect, useState, forwardRef} from 'react';
import ReactMarkdown from 'react-markdown';
import {Box, Heading, Text, List, ListItem, Code, Divider, Link} from '@chakra-ui/react';
import remarkGfm from "remark-gfm";
import SyntaxHighlighter from "react-syntax-highlighter";
import 'github-markdown-css/github-markdown.css';
import {CodeBlock} from "./codeblock";

const MarkdownViewer = forwardRef(({mdfilepath, mdstring, ...props}, ref) => {
    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        if (mdfilepath) {
            const fetchMarkdown = async () => {
                try {
                    const response = await fetch(mdfilepath);
                    const text = await response.text();
                    setMarkdown(text);
                } catch (error) {
                    console.error('Error fetching markdown:', error);
                }
            };

            fetchMarkdown();
        }
        else {
            setMarkdown(mdstring)
        }

    }, [mdfilepath, mdstring]);

    const components = {
        h1: ({children}) => <Heading as="h1" size="2xl">{children}</Heading>,
        h2: ({children}) => <Heading as="h2" size="xl">{children}</Heading>,
        h3: ({children}) => <Heading as="h3" size="lg">{children}</Heading>,
        h4: ({children}) => <Heading as="h4" size="md">{children}</Heading>,
        h5: ({children}) => <Heading as="h5" size="sm">{children}</Heading>,
        h6: ({children}) => <Heading as="h6" size="xs">{children}</Heading>,
        p: ({children}) => <Text mb={4}>{children}</Text>,
        ul: ({children}) => <List styleType="disc" pl={4} mb={4}>{children}</List>,
        ol: ({children}) => <List as="ol" styleType="decimal" pl={4} mb={4}>{children}</List>,
        li: ({children}) => <ListItem mb={2}>{children}</ListItem>,
        blockquote: ({children}) => (
            <Box pl={4} borderLeft="4px" borderColor="gray.200" mb={4}>
                <Text as="blockquote" fontStyle="italic">{children}</Text>
            </Box>
        ),
        code: ({node, inline, className, children, ...props}) => {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? (
                <CodeBlock codeString={String(children).replace(/\n$/, '')} language={match[1]} {...props} />
            ) : (
                <Code {...props} className={className}>
                    {children}
                </Code>
            )
        },
        thematicBreak: () => {
            return <Divider my={4}/>;
        },
        link: ({href, children}) => {
            return <Link href={href} color="teal.500" isExternal>{children}</Link>;
        },
        hr: () => <Divider my={4}/>,
        a: ({href, children}) => <Link href={href} color="teal.500" isExternal>{children}</Link>,
    };


    return (
        <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden" className="markdown-body" {...props} ref={ref}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>
        </Box>
    );
});

export default MarkdownViewer;
 
 
