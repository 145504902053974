import {Box, ChakraProvider} from "@chakra-ui/react";
import React from "react";

export const BackgroundImagePage = ({ children }) => {
  return (
    <ChakraProvider>
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100%"
        backgroundImage="url('background_imageA.png')"
        backgroundSize="cover"
        backgroundPosition="center"
        opacity="0.2" // Adjust opacity here
        zIndex="-1" />
      <Box
        position="relative"
        zIndex="1"
        width="100%"
      >
        {children}
      </Box>
    </ChakraProvider>
  );
};
