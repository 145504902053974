import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "./authprovider";
import {Box, Button, Divider, Flex, HStack, Image, Spacer, VStack} from "@chakra-ui/react";
import React from "react";

const TopBar = () => {

    const navigate = useNavigate();
    const {logout, loginToken, anonymousUser} = useAuth();

    // Handlers to toggle login state
    const handleLogin = () => {
        console.log("Navigating to signin")
        navigate("/signin")
    };

    const handleLogout = () => {
        logout()
        console.log("Navigating to /")
        navigate("/")
    };

    return (
        <VStack>
            <Box bg="gray.100" p={4} position="fixed" top={0} left={0} right={0} zIndex={999}>
                <HStack>
                    <VStack alignItems="flex-start">
                        <Link to="/">
                            <Image src="logo_small.png" alt="Logo" width="35pt"
                                   height="35pt"/> {/* Here is where the logo is added */}
                        </Link>
                        {/*<Text>Increasing UI test coverage with the power of AI</Text>*/}
                    </VStack>
                    <Flex maxW="container.xl" mx="auto" justifyContent="center">
                        <Box>
                            <HStack>
                                <Button as={Link} to="/" variant="ghost" mr={4}>
                                    Home
                                </Button>
                                {loginToken && !anonymousUser ? (
                                    <Button as={Link} to="/testgenerator" variant="ghost">
                                        Test Generator
                                    </Button>
                                ) : (
                                    <Button as={Link} to="/trytestgenerator" variant="ghost">
                                        Try Test Generator
                                    </Button>
                                )}
                                <Button as={Link} to="/instructions" variant="ghost">
                                    Documentation
                                </Button>
                                <Spacer/>
                                {(!loginToken || anonymousUser) && (
                                    <Button as={Link} to="/signup" colorScheme="blue"> Signup </Button>)}
                                {(loginToken && !anonymousUser) &&
                                    <Button as={Link} colorScheme="blue" to="account"
                                            mr={4}> Account </Button>}

                                <Button color="blue.500" onClick={loginToken ? handleLogout : handleLogin}
                                        variant="ghost" mr={4}>
                                    {(loginToken && !anonymousUser)  ? 'Logout' : 'Sign In'}
                                </Button>
                            </HStack>
                        </Box>
                    </Flex>
                </HStack>
            </Box>
            <Divider borderColor="gray.300" m={0}/>
        </VStack>
    );
};

export default TopBar;