import {Box, Button, ButtonGroup} from "@chakra-ui/react";
import React from "react";

export function SegmentedControl({options, selectedItem, setSelectedItem}) {
    return (
        <Box py="1">
            <ButtonGroup variant="outline" isAttached>
                {options.map((item, index) => (
                    <Button
                        borderWidth="1px" borderRadius="lg" borderColor="darkgray"
                        key={index}
                        isActive={item.label === selectedItem}
                        onClick={() => setSelectedItem(item.label)} >
                        {item.label}
                    </Button>
                ))}
            </ButtonGroup>
        </Box>
    );
}