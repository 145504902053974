import React, {useEffect, useState} from 'react';
import {Box, Button, Heading, HStack, Spinner, Text, VStack} from "@chakra-ui/react";
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {CodeBlock} from "./codeblock";
import {CollapsibleTextareaWithInfo} from "./CollapsibleTextareaWithInfo";
import {SegmentedControl} from "./segmentedcontrol";
import Markdownviewer from "./markdownviewer";

function UITestGeneratorLayout({
                                   HeaderComponent,
                                   headerComponentProps,
                                   inputViewHierarchy,
                                   inputViewHierarchyTitle,
                                   viewHierarchyCollapsed,
                                   disableViewHierarchyInput,
                                   onPlatformChange,
                                   showTokenCounts = true
                               }) {
    const [uiTestCode, setUiTestCode] = useState([{"text": {"_0": "Based on the provided information"}}]);
    const [tokenUsage, setTokenUsage] = useState(0);
    const [uiTestDescription, setUiTestDescription] = useState('');
    const [viewHierarchy, setViewHierarchy] = useState(inputViewHierarchy);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isSwitched, setIsSwitched] = useState(false);
    const [errorReason, setErrorReason] = useState("");
    const [tokensAvailable, setTokensAvailable] = useState("");

    const testDescriptionTip = "Provide a detailed description of the test you want to run. Be as descriptive as possible, explicitly indicating the actions you want the test to take and the expected outcomes."
    const viewHierarchyTip = "Provide a dump of the view hierarchy. This can be done using our helper function see the 'Instructions' or sticking a debug point inside a test and running 'po XCUIApplication().debugDescription' then copying the output"

    const handleToggle = () => {
        setIsSwitched(!isSwitched);
    };

    useEffect(() => {
        console.log(inputViewHierarchy)
        setViewHierarchy(inputViewHierarchy);
    }, [inputViewHierarchy]);

    const platformChoices = [
        {label: 'iOS', value: '1'},
        {label: 'macOS', value: '2'}
    ];

    const generateTypes = [
        {label: 'Screenshot', value: '1'},
        {label: 'UI Test', value: '2'}
    ];

    const [selectedPlatform, setSelectedPlatform] = useState("iOS");
    const [selectedType, setSelectedType] = useState("UI Test");

    const descriptionFieldTitle = selectedType === "Screenshot" ? "Description of what to screenshot" : "Description of what to test";

    const betaIssuesNote = "NOTE: The AI sometimes doesn't identify UI elements correctly because it's looking in the wrong part of the hierarchy.\n" +
        "        In most cases this can easily be fixed by telling it to search the top level of the hierarchy rather than the sub element.\n" +
        "        For instance if the code identifies the third row in a list and is trying to get a reference to the delete button in the swipe menu\n" +
        "        ```swift thirdCell.buttons[\"Delete\"]``` this can easily be fixed by changing it to ```swift app.buttons[\"Delete\"]```"



    // Fetch user details
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(`/userdetails`);
                setTokensAvailable(response.data.availableTokens);
            } catch (error) {
                console.error('Failed to fetch available tokens:', error);
            }
        };

        fetchUserDetails();
    }, [tokensAvailable]);

    async function generate() {
        console.log(`${selectedType}`);

        setLoading(true);
        setError(null);
        setErrorReason("")

        try {
            if (selectedType === "Screenshot") {
                console.log("Generating screenshot");
                await callGenerateTestCodeService(true);
            } else {
                console.log("Generating test");
                await callGenerateTestCodeService(false);
            }
        } catch (error) {
            console.log(error);
            const errorData = await error.response.data;
            console.log(errorData);

            console.error("An error occurred:", errorData);
            alert(errorData.reason);
            setErrorReason(errorData.reason);
        } finally {
            setLoading(false);
        }
    }

    async function callGenerateTestCodeService(isScreenshot) {
        const payload = {
            description: uiTestDescription,
            viewHierachy: viewHierarchy,
            platform: selectedPlatform
        };

        const result = await axios.post(isScreenshot ? 'testcreation/generateScreenshotter' : 'testcreation/generateNew', payload);
        console.log(result);

        let creationResponse = result.data
        let testContent = creationResponse.testContent;
        let testUsage = result.data.usage;

        console.log(testContent);
        setUiTestCode(testContent);
        setTokenUsage(testUsage.totalTokens);
        setTokensAvailable(creationResponse.tokensLeft)

        console.log(`The response was ${testContent}`);
    }

    let navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/signup');
    };

    const viewHierachyInfoText = `
    Paste the viewhierachy for your app into the text area below. To capture the viewHierachy run the function below in a UITest
    
    private func copyViewStructureToClipboard() {
        let viewHierachy = XCUIApplication().debugDescription
        print(viewHierachy)
        
#if os(macOS)
        let pasteboard = NSPasteboard.general
        pasteboard.clearContents()
        pasteboard.setString(viewHierachy, forType: .string)
#else
        let pasteboard = UIPasteboard.general
        pasteboard.string = viewHierachy
#endif

    }`;

    const onPlatformChangeInternal = ({value}) => {
        onPlatformChange(value)
        setSelectedPlatform(value)
    }

    const ContentRenderer = ({data}) => {
        return (
            <Box display="flex"
                 width="100%"
                 minHeight="90%"
                 flexDirection="column"
                 flexGrow={1}
                 bg="gray.100"
                 borderWidth="1px"
                 borderColor="gray.200">
                {loading ? (
                    <Box display="flex" alignItems="center" justifyContent="center" w="100%" h="100%">
                        <Spinner size="xl"/>
                    </Box>
                ) : (
                    data.map((item, index) => {
                        if (item.codeBlock) {
                            return (
                                <Box
                                    px={4}
                                    py={4}
                                    display="flex"
                                    flexDirection="column"
                                    flexGrow={1}
                                    borderRadius="sm"
                                    overflow="auto"
                                    maxH="100%"
                                    maxWidth="100%"
                                    maxHeight="100%"
                                    borderWidth="1px"
                                    borderColor="gray.200"
                                >
                                    <CodeBlock
                                        key={index}
                                        codeString={item.codeBlock.code}
                                        language={item.codeBlock.language}
                                        height="100%"
                                        maxWidth="100%"
                                        flexGrow={1}
                                        overflow="auto"
                                        readOnly
                                    />
                                </Box>
                            );

                        }
                        return null
                    })
                )}
            </Box>
        );
    };

    return (
        <VStack height="full" w="full">
            <HeaderComponent {...headerComponentProps} />
            <HStack spacing={10} maxHeight="100vh" w="full" py={5} paddingLeft={5} alignItems="flex-start"
                    justifyContent="flex-start">
                <VStack spacing={10} height="full" w="full">
                    <VStack align="stretch" w="full">
                        <Heading as='h4' size='sm'>What do you want to generate?</Heading>
                        <SegmentedControl options={generateTypes} selectedItem={selectedType}
                                          setSelectedItem={setSelectedType}/>
                        <Heading as='h4' size='sm'>For which platform?</Heading>
                        <SegmentedControl options={platformChoices}
                                          selectedItem={selectedPlatform}
                                          setSelectedItem={(item) => onPlatformChangeInternal({value: item})}/>
                    </VStack>
                    <CollapsibleTextareaWithInfo heading="View Hierarchy"
                                                 contentTitle={inputViewHierarchyTitle}
                                                 popoverBody={viewHierarchyTip}
                                                 placeholderText="Enter view hierarchy..."
                                                 enteredValue={viewHierarchy}
                                                 setEnteredValue={setViewHierarchy}
                                                 disabled={disableViewHierarchyInput}
                                                 initiallyCollapsed={viewHierarchyCollapsed}/>
                    <CollapsibleTextareaWithInfo heading={descriptionFieldTitle}
                                                 popoverBody={testDescriptionTip}
                                                 placeholderText="Enter test description..."
                                                 enteredValue={uiTestDescription}
                                                 setEnteredValue={setUiTestDescription}/>
                    <Button onClick={generate} disabled={loading} size="lg" mb={4}>
                        {loading ? 'Generating...' : 'Generate'}
                    </Button>
                    {errorReason &&
                        <Text fontSize="md" color="red.500" mb={4}>ERROR: {errorReason}</Text>
                    }
                    <Box minH="40pt"><Text>.</Text></Box>
                </VStack>

                <VStack minHeight="250pt" height="100%" width="100%" maxWidth="50vw" maxHeight="100vh" align="stretch">
                    <HStack alignItems="center" justifyContent="space-between">
                        <Heading as='h4' size='sm'>Generated UI Test Code</Heading>
                        {showTokenCounts &&
                            <Text fontSize="md" mb={4}>Tokens Used: {tokenUsage} / Tokens
                                Available: {tokensAvailable}</Text>
                        }
                    </HStack>
                    <ContentRenderer data={uiTestCode}/>
                    <Markdownviewer mdstring={betaIssuesNote}/>
                </VStack>
            </HStack>
        </VStack>
    );
}

export default UITestGeneratorLayout;
