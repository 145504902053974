import React, {createContext, useContext, useState} from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children}) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    function loadLoggedInSession() {
        const token = localStorage.getItem('loginToken');
        const anonUser = localStorage.getItem('anonUser');
        if (token) {
            console.log("Token Found:", token);
            setLoginToken(token)
            setAnonymousUser(anonUser)
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            setIsLoggedIn(true)
        } else {
            console.log("No token found");
            setLoginToken(null)
            setIsLoggedIn(false)
        }
    }

    const [loginToken, setLoginToken] = useState(null);
    const [anonymousUser, setAnonymousUser] = useState(false);

    const loginTryUser = async () => {

        try {
            const response = await axios.get('tryUserLogin', {})

            const data = response.data;

            if (data.value) {
                const loginToken = data.value
                axios.defaults.headers.common['Authorization'] = `Bearer ${loginToken}`;
                console.log(`Common Headers: ${JSON.stringify(axios.defaults.headers.common)}`);
                setLoginToken(loginToken)
                localStorage.setItem('loginToken', loginToken);
                localStorage.setItem('anonUser', true);
                setIsLoggedIn(true)
                setAnonymousUser(true)
                return loginToken
            } else {
                alert('Try user signin failed.');
            }
        } catch (error) {
            console.error('Try user signin error:', error);
        }
    };

    const login = async (email, password) => {

        const encodedCredentials = btoa(`${email}:${password}`);

        try {
            const response = await axios.get('login', {
                headers: {
                    'Authorization': `Basic ${encodedCredentials}`
                }
            })

            const data = response.data;

            if (data.value) {
                const loginToken = data.value
                axios.defaults.headers.common['Authorization'] = `Bearer ${loginToken}`;
                console.log(`Common Headers: ${JSON.stringify(axios.defaults.headers.common)}`);
                setLoginToken(loginToken)
                localStorage.setItem('loginToken', loginToken);
                setIsLoggedIn(true)
                setAnonymousUser(false)
                return loginToken
            } else {
                alert('Signin failed.');
            }
        } catch (error) {
            console.error('Signin error:', error);
            throw error
            // alert('An error occurred during signin.');
        }
    };

    const logout = () => {
        setLoginToken(null);
        setAnonymousUser(false)
        localStorage.removeItem('loginToken');
    };

    return (
        <AuthContext.Provider value={{loadLoggedInSession, loginToken, anonymousUser, isLoggedIn, login, loginTryUser, logout}}>
            {children}
        </AuthContext.Provider>
    );
};
