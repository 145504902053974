import {Box, Button, HStack} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import UITestGeneratorLayout from "./UITestGeneratorLayout";

function InfoHeader() {
    return <Box display="inline-block" bg="rgba(247, 248, 250, 0.4)" borderRadius="md" p={5}
                alignItems="center" justifyContent="center" w="full">
        <HStack alignItems="center" justifyContent="center" w="full">
            <Button as={Link} to="/instructions?tab=1" variant="ghost" color="blue.300"
                    fontSize="18pt">Read Instructions</Button>
        </HStack>
    </Box>;
}

function FullUITestGenerator() {

    const [platform, setPlatform] = useState("iOS");

    return (
        <>
            <UITestGeneratorLayout HeaderComponent={InfoHeader}
                                   onPlatformChange={(value) => setPlatform(value)}/>
        </>
    );
}


export default FullUITestGenerator;