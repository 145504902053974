import React, {useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

import {
    Container, VStack, Input, Button, FormControl, FormLabel, Heading
} from '@chakra-ui/react';

function SignupForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [inviteToken, setInviteToken] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [createdUser, setCreatedUser] = useState(false);

    const navigate = useNavigate();

    const validatePassword = () => {
        if (password === confirmPassword) {
            return true
        } else {
            alert('Passwords do not match!');
            return false
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitted(true);
        if (validatePassword() === false) { return }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Invite-Token': inviteToken,
                // Add other headers as needed
            }
        };

        try {
            const response = await axios.post('signupUser', {
                name,
                email,
                password
            }, config);

            const data = response.data;
            if (data.userId) {
                navigate('/signin', { state: { "createdAccountMessage": "Account created successfully please login below"} });
            } else {
                setError("Signup failed");
            }
        } catch (error) {
            if (error.response.status === 409) {
                setError({message: "Email already exists, please contact us to reset password at support@uitesting.tools"})
            }
            else {
                setError(error)
            }
            // console.error("An error occurred during signup:", error);
        }
    };

    return (
        <Container centerContent p={4}>
            <Heading fontSize="16pt" color="blue.300" pb={10}>Sign up to the Beta for free</Heading>
            <VStack as="form" onSubmit={handleSubmit} spacing={4}>
                {/*<FormControl>*/}
                {/*    <FormLabel>Invite Token</FormLabel>*/}
                {/*    <Input type="text" value={inviteToken} onChange={(e) => setInviteToken(e.target.value)}/>*/}
                {/*</FormControl>*/}
                <FormControl isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Password</FormLabel>
                    <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Confirm Password</FormLabel>
                    <Input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                </FormControl>
                <Button type="submit" colorScheme="blue">Sign Up</Button>
                {error && <FormLabel color="red">{error.message}</FormLabel>}
            </VStack>
        </Container>
    );
}

export default SignupForm;

