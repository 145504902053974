import React, {useState} from 'react';
import axios from 'axios';
import {useAuth} from './authprovider';

import {useNavigate, useLocation} from 'react-router-dom';


import {
    Container, VStack, Input, Button, FormControl, FormLabel
} from '@chakra-ui/react';

function SignInForm({successPath}) {

    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const {login} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState('');

    const {from} = location.state || {from: {pathname: "/"}};
    const { createdAccountMessage } = location.state || {};

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            console.log("Logging in");
            await login(email, password);
            console.log("Login successful, navigating now");
            navigate(successPath);  // Redirect to dashboard after login
        } catch (error) {
            console.error('Failed to login:', error);
            setError({message: "Email or password incorrect"});
        }
    };

    return (
        <Container centerContent p={4}>
            <VStack as="form" onSubmit={handleSubmit} spacing={4}>
                {createdAccountMessage && <FormLabel color="green">{createdAccountMessage}</FormLabel>}
                <FormControl isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Password</FormLabel>
                    <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                </FormControl>
                <Button type="submit" colorScheme="blue">Sign In</Button>
                {error && <FormLabel color="red">{error.message}</FormLabel>}
            </VStack>
        </Container>
    );
}

export default SignInForm;

