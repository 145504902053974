import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import {Box, Button, ChakraProvider, Container, Heading, HStack, useTheme, VStack} from "@chakra-ui/react";

import {BrowserRouter as Router, Link, Navigate, Outlet, Route, Routes} from 'react-router-dom';

import SignupForm from './signup'
import SignInForm from './signin'
import AccountDetails from './account'
import {BackgroundImagePage} from "./background_image";
import {AuthProvider, useAuth} from './authprovider';
import ProtectedRoute from './protectedroute';
import {Instructions} from "./instructions";
import HomePage from "./homepage";
import TopBar from "./TopBar";
import TryUITestGenerator from "./TryUITestGenerator";
import FullUITestGenerator from "./FullUITestGenerator";

function MainContent() {

    return (
        <Box flex="1" p={0}>
            <VStack>
                <Heading display='none'> Main Content Area </Heading>
                <Outlet/>
            </VStack>
        </Box>
    );
}

export const MainLayout = () => {

    const {loadLoggedInSession} = useAuth();

    const theme = useTheme();
    console.log(theme); // Logs the entire theme object

    // To specifically check breakpoints:
    console.log(theme.breakpoints);

    useEffect(() => {
        loadLoggedInSession()
    }, []);

    return (
        <Box>
            <TopBar/>
            <VStack spacing={0} mx={0} px={0} pt="22pt">
                <BackgroundImagePage>
                    <Container maxW="100%" pt="50px">
                        <MainContent/>
                    </Container>
                </BackgroundImagePage>
            </VStack>
        </Box>
    )
}

function InfoHeader() {
    return <Box display="inline-block" bg="rgba(247, 248, 250, 0.4)" borderRadius="md" p={5}
                alignItems="center" justifyContent="center" w="full">
        <HStack alignItems="center" justifyContent="center" w="full">
            <Button as={Link} to="/instructions?tab=1" variant="ghost" color="blue.300"
                    fontSize="18pt">Read Instructions</Button>
        </HStack>
    </Box>;
}

function App() {

    return (
        <Container minW="95%" py={10}>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<MainLayout/>}>
                        <Route index element={<HomePage/>}/> {/* Redirect to homepage on mount */}
                        <Route path="/instructions" element={<Instructions/>}/>
                        <Route path="/testgenerator" element={<ProtectedRoute><FullUITestGenerator/></ProtectedRoute>}/>
                        <Route path="/trytestgenerator" element={<TryUITestGenerator/>}/>
                        <Route path="/account" element={<ProtectedRoute><AccountDetails/> </ProtectedRoute>}/>
                        <Route path="/signin" element={<SignInForm successPath="/testgenerator"/>}/>
                        <Route path="/signup" element={<SignupForm/>}/>
                        <Route path="*" element={<Navigate to="/" replace/>}/>
                    </Route>
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                </Routes>
            </AuthProvider>
        </Container>
    );
}

const rootElement = document.getElementById("app");

const root = ReactDOM.createRoot(rootElement);
root.render(
    <ChakraProvider>
        <Router>
            <App/>
        </Router>
    </ChakraProvider>
);
