// Import necessary modules
import React from 'react';
import {Box, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, VStack} from "@chakra-ui/react";
import MarkdownViewer from "./markdownviewer"
import {useLocation} from "react-router-dom";

const DemoPage = () => {
    return (
        <VStack alignItems="flex-start" spacing={2}>
            <Heading fontSize="x-large" p={5} color="blue.300">Demo of generating screenshots for the AppStore</Heading>

            {/*<iframe className="rumble" width="800" height="450"*/}
            {/*        src="https://rumble.com/embed/v4y8hpn/?pub=3pxxik" frameBorder="0"*/}
            {/*        allowFullScreen></iframe>*/}

            <video width="100%" controls>
                <source src="screenshot_demo_1080p.mov" type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </VStack>
    )
}

export const Instructions = () => {

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();
    const tabIndex = parseInt(query.get('tab'), 10) || 0;

    return (
        <Box p={4} w="full" alignContent="left">
            <Tabs defaultIndex={tabIndex}>
                <TabList>
                    <Tab>Demos</Tab>
                    <Tab>Instructions</Tab>
                    <Tab>XCUITest Setup</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <DemoPage/>
                    </TabPanel>
                    <TabPanel>
                        <MarkdownViewer mdfilepath="Instructions.md"/>
                    </TabPanel>
                    <TabPanel>
                        <MarkdownViewer mdfilepath="XCUITestSetupGuide.md"/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};