import React, {useEffect, useState, useRef} from 'react';
import UITestGeneratorLayout from './UITestGeneratorLayout'
import {Box, Button, Heading, HStack, Image, Link, Spacer, VStack} from "@chakra-ui/react";
import {useAuth} from "./authprovider";
import axios from "axios";
import MarkdownViewer from "./markdownviewer";
import {Link as RouterLink} from "react-router-dom";

function ExampleInfoHeader({imageFile}) {

    const [showHStack, setShowHStack] = useState(false);

    const instructionsRef = useRef(null);
    const [maxImageHeight, setMaxImageHeight] = useState(0);

    useEffect(() => {
        if (instructionsRef.current) {
            setMaxImageHeight(instructionsRef.current.clientHeight);
        }
    }, [instructionsRef]);

    return (
        <VStack>

            <Box display="inline-block" bg="rgba(247, 248, 250, 0.4)" borderRadius="md" p={5}
                 alignItems="center" justifyContent="center" w="full">
                <HStack alignItems="center" justifyContent="center" w="full">

                    <VStack alignItems="center" justifyContent="center" w="full">
                        <Heading size="md" color="blue.300" m={5}>Try Our (Beta) UI Test Generation
                            Capabilities</Heading>
                        <Heading size="sm" m={1}>Explore our UI test generation capabilities by asking it to generate
                            tests
                            for our
                            sample To-Do list project.
                        </Heading>
                        <Heading size="sm" m={1}>See more details and suggestions by clicking "More" below.</Heading>
                        <HStack>
                            <Button
                                onClick={() => setShowHStack(!showHStack)}>{showHStack ? "Less" : "More..."}</Button>
                            <Link href="https://github.com/glaciotech/DemoTaskList" isExternal>
                                <Button colorScheme="blue">Download Example Project</Button>
                            </Link>
                        </HStack>
                        {showHStack &&
                            <VStack>
                                <HStack mt={3}>
                                    <MarkdownViewer mdfilepath="trynow_instructions.md" width="55%"/>
                                    <Spacer/>
                                    <Image src={imageFile} width="45%" maxHeight="1000px" alt="Demo Task List"
                                           objectFit="scale-down"/>
                                </HStack>
                            </VStack>
                        }

                    </VStack>
                </HStack>
            </Box>
        </VStack>
    );
}

function TryUITestGenerator() {

    const [platform, setPlatform] = useState("iOS");
    const {loginTryUser} = useAuth();
    const [exampleViewHierarchy, setExampleViewHierarchy] = useState('');
    const [error, setError] = useState('');

    function handlePlatormChange(value) {
        if (value === "iOS") {
            setPlatform(value);
        } else {
            alert("Signup to generate tests for macOS projects")
        }
    }

    useEffect(() => {
        const fetchExampleViewHierarchy = async () => {
            try {
                const response = await axios.get('exampleViewHierarchy?platform=' + platform);
                setExampleViewHierarchy(response.data);
            } catch (err) {
                setError(`Failed to fetch example view hierarchy for platform ${platform}`);
                console.error(err);
            }
        };

        fetchExampleViewHierarchy();

    }, [platform]);

    useEffect(() => {
        const handleTryUserSignIn = async () => {
            try {
                console.log("Logging in try user");
                await loginTryUser();
                console.log("Login try user successful, navigating now");
                // navigate(successPath);  // Redirect to dashboard after login
            } catch (error) {
                console.error('Failed to login:', error);
            }
        };

        handleTryUserSignIn()
    }, []);

    return (
        <>
            <VStack>
            <Box display="inline-block" bg="rgba(247, 248, 250, 0.97)" borderRadius="sm" p={0}
                 alignItems="center" justifyContent="center" w="full">
                <HStack alignItems="center" justifyContent="center" w="full">
                    <Heading size="md" color="blue.300" m={5}>To use your own project, signup free.</Heading>
                    <Button as={RouterLink} to="/signup" colorScheme="blue">Signup</Button>
                </HStack>
            </Box>
            <UITestGeneratorLayout HeaderComponent={ExampleInfoHeader}
                                   headerComponentProps={{imageFile: (platform === "iOS" ? "DemoTaskListMain_ios.png" : "DemoTaskListMain_macOS.png")}}
                                   inputViewHierarchyTitle={platform === "iOS" ? "iOS Example Project View Hierarchy" : "macOS Example Project View Hierarchy"}
                                   inputViewHierarchy={exampleViewHierarchy ? exampleViewHierarchy : error}
                                   disableViewHierarchyInput={true}
                                   onPlatformChange={(value) => handlePlatormChange(value)}
                                   viewHierarchyCollapsed={true}
                                   showTokenCounts={false}
            />
            </VStack>
        </>
    );
}

export default TryUITestGenerator;